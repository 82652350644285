import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import BecomeACarrier from '../components/BecomeACarrier'

const PaymentServicesPage = ({ data }) => {
  const postNode = {
    title: `Payment Services - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Payment Services - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="payment-services" customTitle />

      <ServiceContainer>
        <PageTitle>Payment Services</PageTitle>
        <LargeP>
          At D&D, we are happy to pay for a job well done and pride ourselves on
          quick and consistent pay.
        </LargeP>
        <p>
          We know that cash-flow is critical in the trucking industry and we
          treat payment with a great deal of importance and sense of urgency.
          Our digital payment process is reliable and efficient ensuring fast
          compensation, reimbursements and cash transfers as required.We employ
          the best technologies to mitigate error and expedite the process
          whether it is a paycheck or reimbursement. No fee direct deposit, cash
          advances and fast fuel reimbursements are just a few of D&D payment
          benefits.
        </p>

        <BecomeACarrier />

        <h4>Payment Services at a Glance</h4>
        <FlexList>
          <FlexItem>Direct Deposit</FlexItem>
          <FlexItem>Quick Pay</FlexItem>
          <FlexItem>Cash Advance Options</FlexItem>
          <FlexItem>Mobile App Submissions</FlexItem>
          <FlexItem>Mobile App Deposits</FlexItem>
          <FlexItem>Fast Claim Resolutions</FlexItem>
          <FlexItem>Fleet & Fuel Cards</FlexItem>
          <FlexItem>Fuel Discounts</FlexItem>
          <FlexItem>24/7, 365 Driver Support</FlexItem>
        </FlexList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default PaymentServicesPage
